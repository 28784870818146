import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import {
  AuditPlanDetailsDTO,
  AuditPlanFormDTO,
  AuditPlanListDTO,
} from '../dto/audit-plan.dto';
import { MasterConversion } from '../../../../shared/conversion/master.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditPlanConversion
  implements
    MainConversionInterface<
      AuditPlanListDTO,
      AuditPlanFormDTO,
      AuditPlanDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private masterConv: MasterConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditPlanListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        audit_category_id: item.audit_category_id,
        audit_category: item['audit_category_title_' + this.lang],
        audit_team_id: item.audit_team_id,
        team_lead_id: item.team_lead_id,
        title: item.title,
        start_date: item.start_date,
        target_date: item.target_date,
        audit_status_id: item.audit_status_id,
        audit_status: this.tableConv.resToColordItem(item, 'audit_status'),
        audit_status_type: item.audit_status_type,
        team_lead: this.userConv.resToUserMinimalIdFixedDTO(item, 'team_lead'),
        audit_team_team_lead: this.userConv.resToUserMinimalIdFixedDTO(
          item,
          'audit_team_team_lead'
        ),
        audit_team: item['audit_team_title_' + this.lang],
        created_user: this.userConv.resToUserMinimalIdFixedDTO(
          item,
          'created_user'
        ),
        updated_user: this.userConv.resToUserMinimalIdFixedDTO(
          item,
          'updated_user'
        ),
        created_at: item.created_at,
        updated_at: item.updated_at,
        created_by_id: item.created_by_id,
        updated_by_id: item.updated_by_id,
      };
    });
    return result;
  }
  formGroupToForm(formGroup: FormGroup): AuditPlanFormDTO {
    return {
      audit_category_id: parseInt(formGroup.get('AuditCategory')?.value),
      audit_team_id: parseInt(formGroup.get('AuditTeam')?.value),
      team_lead_id: parseInt(formGroup.get('TeamLead')?.value),
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      start_date: formGroup.get('StartDate')?.value,
      target_date: formGroup.get('TargetDate')?.value,
      purpose: formGroup.get('Purpose')?.value,
      objective: formGroup.get('Objective')?.value,
      user_ids: formGroup.get('Users')?.value,
    };
  }
  resToForm(response: any): any {
    return {
      Id: response.id,
      Title: response.title,
      Description: response.description,
      AuditCategory: response.audit_category?.id,
      AuditTeam: response.audit_team?.id,
      TeamLead: response.team_lead?.id,
      StartDate: response.start_date,
      TargetDate: response.target_date,
      Purpose: response.purpose,
      Objective: response.objective,
      Users: response.members.map((user: any) => user.user_id),
    };
  }
  resToDetails(response: any): AuditPlanDetailsDTO {
    //TODO
    return {
      id: response.id,
      reference_number: response.reference_number,
      audit_category: this.masterConv.resToDetails(response.audit_category),
      audit_team: this.masterConv.resToDetails(response.audit_team),
      team_lead: this.userConv.resObjToUserMinimalDTO(response.team_lead),
      title: response.title,
      description: response.description,
      start_date: response.start_date,
      target_date: response.target_date,
      purpose: response.purpose,
      objective: response.objective,
      audit_status: this.masterConv.resToDetails(response.audit_status),
      published_date: response.published_date,
      published_by: this.userConv.resObjToUserMinimalDTO(response.published_by),
      created_at: response.created_at,
      updated_at: response.updated_at,
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
      auditable_items: response.auditable_items,
      members:
        response.members && response.members.length > 0
          ? response.members.map((userItem: any) =>
              this.userConv.resObjToUserMinimalDTO(userItem.user)
            )
          : [],
    };
  }
}
